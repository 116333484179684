<template>
    <div class="st-container canlender_schedule" :style="'min-width: 1300px'">
    <!-- <div class="st-container" v-if="scheduleCanlenderList.length" :style="'min-width: 1300px'"> -->
        <!-- controller -->
        <div class="st-controller-container">
            <div class="st-controller-group">
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'year',
                            isIncrease: false,
                            val: year,
                        })
                    "
                >
                    <i class="icon icon-arrow_left"></i>
                </button>
                <span class="st-controller-subject canlender_year">{{ year }}</span>
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'year',
                            isIncrease: true,
                            val: year,
                        })
                    "
                >
                    <i class="icon icon-arrow_right"></i>
                </button>
            </div>

            <div class="st-controller-group">
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'month',
                            isIncrease: false,
                            val: month,
                        })
                    "
                >
                    <i class="icon icon-arrow_left"></i>
                </button>
                <span class="st-controller-subject canlender_month">{{ month }}月</span>
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'month',
                            isIncrease: true,
                            val: month,
                        })
                    "
                >
                    <i class="icon icon-arrow_right"></i>
                </button>
            </div>

            <router-link
                to="/schedule"
                class="switch_calendar"
            >
                <img 
                    src="@/assets/tgd/calendar/list.svg"
                    alt=""
                >
            </router-link>
        </div>

        <!-- schedule canlender -->
        <FullCalendar ref="fullCalendar" 
            :options="calendarOptions" 
        />
    </div>

    <div id="detail_list_wrap">
        <div class="inner" v-if="matchedDays.length > 0">
            <div class="list_title">芳療師</div>
            <div id="detail_list">
                <a 
                    href="javascript:;"
                    v-for="day in matchedDays" 
                    :key="day.days"
                    :class="getClassState(day)"
                    @click="
                        confirmWarn(
                            day.roster_days_id, 
                            day.eventDate,
                            day.member_name,
                            day.member_id,
                        );
                        sentEventInfo(day.member_id, day.days);
                    "
                >
                    <div class="member_name" :style="{ background: getColorByLeaveType(day.leave_type) }">
                        <span>{{ matchMember(day.member_id).member_code }}</span>
                        <i>|</i>
                        <span>{{ matchMember(day.member_id).nickname }}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
    
</template>

<script>
import { ref, reactive, computed, watch, nextTick, onMounted, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import moment from "moment";
// import { useForm } from "vee-validate";
// import { debounce } from "lodash";

import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import SwalCustom from "@/hooks/common/useSwalCustom";
import Swal from "sweetalert2";

import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { basePost } from "@/js/services/baseService";

export default {
    name: "StConainer_canlender",
    emits: ['memberIdSelected', 'selectedEventDate'],
    components: {
        FullCalendar,        
    },
    props: {
        viewAllSchedules: {
            type: Boolean,
            default: false
        },
        selectedAromaId: {
            type: [Number, String],
            default: 0
        },
        selectedLeaveType: {
            type: [Number, String],
            default: ''
        }
    },
    setup(props, { emit }) {
        const { state, getters, commit, dispatch } = useStore();
        const year = computed(() => Number(getters["schedule/getYear"]));
        const month = computed(() => Number(getters["schedule/getMonth"]));
        const days = computed(() => Number(getters["schedule/getDays"]));
        const scheduleList = computed(() => state.schedule.scheduleList);
        const aromatherapistList = computed(() => state.reservation.aromatherapistList);

        const getScheduleList = () => {
            dispatch("schedule/getScheduleList", {
                store_id: state.auth.store_id,
                year: year.value,
                month: month.value,
            })
        };

        // console.log('現在日期',`${year.value}-${month.value}`)
        
        // 發送資訊到Schedule_canlender.vue
        const sentEventInfo = (memberID, eventDays) => {
            emit('memberIdSelected', memberID);
            emit('selectedEventDate', eventDays);
        }

        // 切換日期
        const updateDate = (params) => {
            const { updateType, isIncrease, val } = params;

            let num = isIncrease ? val + 1 : val - 1;
            let updatedVal = "";
            let updatedYear = year.value;

            switch (updateType) {
                case "year":
                    if (isIncrease) {
                        fullCalendarAPI.value.nextYear();
                    } else {
                        if (num <= 0) {
                            num = 0; 
                        }
                        fullCalendarAPI.value.prevYear();
                    }
                    updatedVal = `${num}-${month.value}`;
                    break;

                case "month":
                    if (isIncrease) {
                        if (num > 12) {
                            num = 1;
                            updatedYear += 1;
                        }
                        fullCalendarAPI.value.next();
                    } 
                    else {
                        if (num <= 0) {
                            num = 12;
                            updatedYear -= 1;
                        }
                        fullCalendarAPI.value.prev();
                    }

                    updatedVal = `${updatedYear}-${num.toString().padStart(2, '0')}`;
                    break;
            }

            commit("schedule/update", {
                key: "currentDate",
                value: updatedVal,
            });

            getScheduleList();
            updateCanlender();
        };

        onMounted(() => {
            getScheduleList();
        });
        

        // 依照leave_type的值取得假別顏色
        function getColorByLeaveType(leave_type) {
            let color;
            switch (leave_type) {
                case 1:
                    color = 'rgba(183, 127, 26, 1)';
                    break;
                case 2:
                    color = 'rgba(109, 69, 69, 1)';
                    break;
                case 3:
                    color = 'rgba(0, 121, 242, 1)'; 
                    break;
                case 4:
                    color = 'rgba(5, 170, 102, 1)'; 
                    break;
                case 5:
                    color = 'rgba(255, 65, 111, 1)'; 
                    break;
                case 9:
                    color = 'rgba(0, 0, 0, 1)'; 
                    break;  
                case 10:
                    color = 'rgba(255, 174, 21, 1)'; 
                    break;          
                default:
                    color = 'rgba(255, 255, 255, 1)'; 
            }
            return color;
        }

        // 找到日歷點擊的那天相對應的芳療師並輸出其資訊
        function matchMemberInClickDate(date){
            const matchMemberInScheduleList = scheduleList.value.find(member => 
                member.member_id === props.selectedAromaId 
            );

            const matchDay = matchMemberInScheduleList.days.find(day => 
                day.days === date
            );

            // 如果沒有排班
            if(matchMemberInScheduleList.arrange_id == ''){
                selectAClassFirst.fire();
                return null
            }

            if(matchMemberInScheduleList){
                return {
                    // 符合芳療師id的資料
                    arrange_id: matchMemberInScheduleList.arrange_id,
                    attendance: matchMemberInScheduleList.attendance,
                    class_status: matchMemberInScheduleList.class_status,
                    class_status_yes_datetime: matchMemberInScheduleList.class_status_yes_datetime,
                    member_id: matchMemberInScheduleList.member_id,
                    ranks_name: matchMemberInScheduleList.ranks_name,
                    roster_month_id:matchMemberInScheduleList.roster_month_id,
                    // 以下是符合點擊日期的資料
                    actual_end_time:matchDay.actual_end_time,
                    actual_start_time:matchDay.actual_start_time,
                    arrangeStr:matchDay.arrangeStr,
                    class_one:matchDay.class_one,
                    class_two:matchDay.class_two,
                    course:matchDay.course,
                    leave_type:matchDay.leave_type,
                    make_up:matchDay.make_up,
                    note:matchDay.note,
                    old_point_amout:matchDay.old_point_amout,
                    one_overtime:matchDay.one_overtime,
                    overtime:matchDay.overtime,
                    pingban:matchDay.pingban,
                    roster_days_id:matchDay.roster_days_id,
                    scheduled_end_time:matchDay.scheduled_end_time,
                    scheduled_start_time:matchDay.scheduled_start_time,
                    support_store_id:matchDay.support_store_id,
                    toster_day_move_id:matchDay.toster_day_move_id,
                    toster_day_source_id:matchDay.toster_day_source_id,
                    toster_day_source_id_date:matchDay.toster_day_source_id_date,
                    total_pingban:matchDay.total_pingban,
                    week:matchDay.week,
                };
            } 

            return null;
        }

        // 找到選擇的芳療師並輸出其資訊
        function matchMember(memberId){
            const matchMember = aromatherapistList.value.find(member => member.id === memberId);
            if(matchMember){
                return {
                    nickname: matchMember.nickname,
                    member_code: matchMember.member_code,
                };
            }
            return null;
        }

        // 將行程表轉成Fullcalendar可用的資料
        function processSchedule(member) {
            return member.days.filter(day => 
                ((day.leave_type !== null && day.leave_type !== 0) && day.support_store_id === 0 && day.toster_day_move_id === null) || 
                (day.leave_type === null && day.support_store_id > 0)
            )
            .map(day => {
                const startDate = moment(`${year.value}-${month.value}-${day.days} ${day.scheduled_start_time}`, "YYYY-MM-DD HH:mm").format();
                const endDate = moment(`${year.value}-${month.value}-${day.days} ${day.scheduled_end_time}`, "YYYY-MM-DD HH:mm").format();
                
                // 使用當前member的id來獲取詳細資料
                const memberDetails = matchMember(member.member_id);
                const color = getColorByLeaveType(day.leave_type);

                return {
                    start: startDate,
                    end: endDate,
                    allDay: false,
                    member_id: member.member_id,
                    extendedProps: {
                        nickname: memberDetails ? memberDetails.nickname : '',
                        member_code: memberDetails ? memberDetails.member_code : '',
                        leave_type: day.leave_type,
                        color: color,
                    },
                    classNames: ['schedule_days'].concat(
                        day.support_store_id > 0 ? ['support_days'] :
                        day.toster_day_source_id !== null ? ['move_days'] :  
                        []
                    )
                };
            });
        }    
        
        // 添加日曆事件
        function addToCalendarFunction(clickedDate){
            let dayOfMonth = clickedDate.getDate(); 
            const memberDayDetails = matchMemberInClickDate(dayOfMonth);

            if (!memberDayDetails) {
                console.log('選擇的芳療師沒有排班所以中止');
                return;
            }
            
            const addToCanlender = async () => {

                const addCanlenderData = {
                    store_id: state.auth.store_id,
                    member_id: props.selectedAromaId,
                    year: year.value,
                    month: month.value,
                    day: days.value,
                    roster_days_id: memberDayDetails.roster_days_id,
                    date:'',
                    leave_type: props.selectedLeaveType,
                    leave_ask_type:'',
                    leave_reason:'',
                    arrange_id: memberDayDetails.arrange_id,
                    pingban:'',
                    overtime:'',
                    make_up:'',
                    support_store_id: props.selectedLeaveType == 8 ? 3 : null,
                    remark: '',
                    one_overtime:'',       
                }

                // console.log('日歷新增的資料',addCanlenderData)

                try {
                    const res = await basePost("/api_roster/leave_check", addCanlenderData);
                    const { status, data } = res;

                    if (status === 200 && data.res_code === 1) {

                        // 重新請求當前日期的假表清單
                        dispatch("schedule/getScheduleList", {
                            store_id: state.auth.store_id,
                            year: getters["schedule/getYear"],
                            month: Number(getters["schedule/getMonth"]),
                        });

                        updateCanlender();
                    } 
                    else {
                        customSwal.fire({
                            text: data.res_content,
                        });
                    }
                } 
                catch (err) {
                    console.error(err);
                    customSwal.fire({
                        text: err.message,
                    });
                }
            };

            addToCanlender();
        }

        const customSwal = new SwalCustom({
            title: "錯誤!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });

        const selectAClassFirst = new SwalCustom({
            title: "需要先選擇班別",
            icon: "warning",
            confirmButtonText: "確定",
        });
        
        const store = useStore();
        const events = computed(() => {
            if(props.selectedAromaId !== 0 && !props.viewAllSchedules){ 
                return scheduleList.value
                    .filter(member => member.member_id === props.selectedAromaId)
                    .flatMap(processSchedule);
            } else {
                return scheduleList.value.flatMap(processSchedule);
            }
        });

        // 取得對應的class
        const getClassState = (day) => {
            if(day.classState == 0) {
                return 'schedule_days';
            } else if(day.classState == 1) {
                return 'support_days';
            } else {
                return 'move_days';
            }
        };

        // 用日曆點擊的日期產生底部列表的渲染資料
        const bottomListRenderDate = ref(null);
        const matchedDays = computed(() => {
            if (!bottomListRenderDate.value) return [];
            const clickedDate = parseInt(moment(bottomListRenderDate.value).format("D"), 10); 
            const eventDate = bottomListRenderDate.value; 
            const scheduleList = store.state.schedule.scheduleList;

            return scheduleList.flatMap(member =>
                member.days
                .filter(day =>
                    day.days === clickedDate &&
                    (
                        (day.leave_type !== null && day.leave_type !== 0 && day.support_store_id === 0 && day.toster_day_move_id === null) ||
                        (day.leave_type === null && day.support_store_id > 0)
                    )
                )
                .map(day => {
                    let classState = 0;
                    if (day.support_store_id > 0) {
                        classState = 1;
                    }
                    if (day.toster_day_source_id !== null) {
                        classState = 2;
                    }
                    return { 
                        ...day, 
                        member_name: member.member_name, 
                        member_id: member.member_id, 
                        eventDate: eventDate,
                        classState: classState 
                    };
                })
            );
        });

        // 點擊日歷事件或日期新增排假
        function clickCanlenderEventOrDate (date){
            if(!props.viewAllSchedules){
                if(props.selectedAromaId !== 0 && props.selectedLeaveType !== null){
                    addToCalendarFunction(date);    
                }else{
                    bottomListRenderDate.value = date
                }
            }else{ 
                bottomListRenderDate.value = date
            }
        }

        // Fullcalendar
        const fullCalendar = ref();
        const fullCalendarAPI = computed(() => {
            if (!fullCalendar.value) return;
            return fullCalendar.value.getApi();
        });

        const initialDate = `${year.value}-${month.value.toString().padStart(2, '0')}-${days.value.toString().padStart(2, '0')}`;

        const calendarOptions = {
            plugins: [dayGridPlugin, interactionPlugin],
            initialView: "dayGridMonth",
            initialDate: initialDate,
            headerToolbar: {
                left: "",
                center: "",
                right: "",
            },
            aspectRatio: 2.08,
            dayMaxEvents: 8, 
            moreLinkContent: function(args) {
                return `還有${args.num}位`;
            },
            events: (info, successCallback, failureCallback) => {
                successCallback(events.value);
            },
            eventOrder: ["time", "title"],
            eventContent: function(arg) {

                if (arg.event.classNames.includes("schedule_days")) {
                    const wrapperDiv = document.createElement('div');
                    wrapperDiv.className = 'member_name';

                    const span = document.createElement('span');
                    span.innerText = arg.event.extendedProps.member_code;

                    const innerspan = document.createElement('span');
                    innerspan.innerText = arg.event.extendedProps.nickname;

                    const separator = document.createElement('i');
                    separator.appendChild(document.createTextNode('|'));

                    wrapperDiv.appendChild(span);
                    wrapperDiv.appendChild(separator); 
                    wrapperDiv.appendChild(innerspan);

                    wrapperDiv.style.backgroundColor = arg.event.extendedProps.color;

                    return { domNodes: [wrapperDiv] };
                }
                
            },
            eventClick(info) {
                let canlenderClickDate = info.event.start;
                clickCanlenderEventOrDate(canlenderClickDate)
            },
            dateClick(info) {
                let canlenderClickDate = info.date; 
                clickCanlenderEventOrDate(canlenderClickDate)
            }
        };

        // 更新canlender
        function updateCanlender() {
            if (fullCalendar.value) {
                const calendarApi = fullCalendar.value.getApi();
                calendarApi.refetchEvents(); 
            } else {
                console.error("FullCalendar實例未定義");
            }
        }

        watch(events, () => {
            updateCanlender()
        });

        // 打開排假燈箱
        const { openShiftLightbox } = useHandleLightboxOpen();
        const memberType = computed(() => state.auth.member_type);

        const confirmWarn = (id, eventDate, memberName, memberId) => {

            // 是否為當天
            const isToday = computed(() => {
                return moment(eventDate).isSame(moment(), "day");
            });

            // 是否是過去時間
            const isBeforeToday = computed(() => {
                return moment(eventDate).isBefore(moment(), 'day');
            });

            if (isToday.value) {
                Swal.fire({
                    title: '當天排假需於芳療師下班打卡前修改確認',
                    icon: 'warning',
                    confirmButtonText: '確定',
                }).then(() => {
                    handleOpenShiftLightbox(id, eventDate, memberName, memberId);
                });
                return;
            } else if(isBeforeToday.value) {
                Swal.fire({
                    title: `${moment(eventDate).month() + 1}月${moment(eventDate).date()}日排假確認後無法取消，是否要繼續進行排假`,
                    icon: 'warning',
                    confirmButtonText: '確定',
                    showCancelButton: true,
                    cancelButtonText: "取消",
                }).then((result) => {
                    if (result.value) {
                        handleOpenShiftLightbox(id, eventDate, memberName, memberId);
                    }
                });
                return;
            }
            handleOpenShiftLightbox(id, eventDate, memberName, memberId);
        };

        const handleOpenShiftLightbox = async (id, eventDate, memberName, memberId) => {
            
            // 若非主管就不能編輯排假
            if (memberType.value !== 3) return;

            if (!id) {
                newSwal.fire();
                return;
            }

            const { store_id } = state.auth;

            commit("schedule/update", {
                key: "shiftMemberInfo",
                value: {
                    name: memberName,
                    date: moment(eventDate).format("YYYY/MM/DD"),
                },
            });

            await dispatch("schedule/getLeaveInfo", {
                store_id,
                roster_days_id: id,
                member_id: memberId,
                date: eventDate,
            });

            await dispatch("schedule/getAllowedChangeDates", {
                store_id,
                member_id: memberId,
            });

            openShiftLightbox();
        };

        return { 
            year, 
            month, 
            days, 
            events,
            scheduleList, 
            updateDate,
            calendarOptions, 
            fullCalendar,
            matchedDays,
            getClassState,
            getColorByLeaveType,
            matchMember,
            moment,
            confirmWarn,
            sentEventInfo,
            initialDate
        };
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";


.canlender_schedule {
    position: relative;

    .calendar-controller {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -55px;
        margin-bottom: 26px;
        font-size: 16px;
        font-weight: 600;

        .controller-wrapper {
            display: flex;
            align-items: center;
            padding: 0 10px;
        }

        .btn-controller {
            border: none;
            background-color: transparent;
        }

        .txt {
            padding: 0 20px;
        }
    }

    .content-panel {
        padding: 46px 75px;
        position: relative;
        background: linear-gradient(-13deg, #fff, rgba(229, 229, 229, 1), #fff);
        @media (max-width: 1200px) {
            padding: 20px 15px;
        }
    }

    // fullcalendar

    table[role="presentation"]{
        tbody[role="presentation"]{
            tr[role="row"]{
                td[role="gridcell"]:first-child,
                td[role="gridcell"]:last-child{
                    background: #EBEBED !important;
                }
            }
        }
    }

    .fc-media-screen {
        background-color: #fff;
    }

    .fc {
        .fc-toolbar.fc-header-toolbar {
            margin: 0;
        }

        .fc-daygrid-day.fc-day-today {
            background-color: #F1E2B4;
        }
        .fc-daygrid-day.weekend {
            background: #EBEBED;
        }
    }

    .fc-scrollgrid {
        border: none;
    }

    .fc-col-header-cell {
        padding: 10px;
    }

    .fc-scrollgrid-section {
        > th,
        > td {
            border: none;
        }
    }

    .fc-col-header,
    .fc-scrollgrid-sync-table {
        th,
        td {
            border-color: rgba(245, 245, 245, 1);

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .fc-col-header-cell-cushion {
        font-size: 16px;
        color: rgba(52, 52, 52, 1);
    }

    .fc-daygrid-day-number {
        font-size: 14px;
        color: rgba(52, 52, 52, 1);
    }

    .fc-theme-standard td, .fc-theme-standard th{
        border: 1px #000 solid !important;
    }

    .fc-theme-standard th{
        border-bottom: 0 !important;
    }
    
    .fc-daygrid-day-events{
        display: flex;
        flex-wrap: wrap;
        .fc-daygrid-event-harness {
            display: flex;
            width: 86px;
            height: 28px;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            position: relative;
            margin:5px 2.5px;
            a{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0 3px 6px #00000029;
                z-index: 2;
                .member_name{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    color:#fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: normal;
                    i{
                        line-height: normal;
                        position: relative;
                        top: -1px;
                        opacity: .5;
                    }
                    span{
                        padding:0 2px;
                        &:first-child{
                            font-weight: bold;
                        }
                    }
                }
                &.support_days{
                    border: 1px #000 solid;
                    .member_name{
                        color: #000;
                    }
                }
                &.move_days{
                    .member_name{
                        &:after{
                            content: '(挪)';
                        }
                    }
                }
            }
            &.fc-daygrid-event-harness-abs{
                position: absolute;
                z-index: 1;
            }
        }
        .fc-daygrid-day-bottom{
            width: 100%;
            text-align: left;
            padding: 0 2.5px;
            a{
                color: #000;
            }    
        }
    }
    .fc-popover{
        .fc-popover-body{
            display: flex;
            flex-direction: row;
            flex-wrap:wrap ;
            max-width: 220px;
        }
        .fc-daygrid-event-harness{
            a{
                display: flex;
                overflow: hidden;
                width: 86px;
                height: 28px;
                font-size: 12px;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;   
                position: relative;
                .member_name{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    color:#fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        line-height: normal;
                        position: relative;
                        top: -1px;
                        opacity: .5;
                    }
                    span{
                        padding:0 2px;
                        font-size: 12px;
                        &:first-child{
                            font-weight: bold;
                        }
                    }
                }
                &.support_days{
                    border: 1px #000 solid;
                    .member_name{
                        color: #000;
                    }
                }
                &.move_days{
                    .member_name{
                        &:after{
                            content: '(挪)';
                        }
                    }
                }
            } 
        }   
        .fc-daygrid-event-harness:nth-child(n+3){
            margin-top: 3px;
        } 
    }
    
}

.aroma-select {
    max-width: 270px;
}

.fc-daygrid-event {
    cursor: pointer;
}

.st-controller-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 600;

    .st-controller-group {
        display: flex;
        padding: 0 20px;
    }

    .st-controller {
        border: none;
        background-color: transparent;
    }

    .st-controller-subject {
        padding: 0 20px;
    }
}

.switch_calendar{
    border: 0;
    width: 50px;
    height: 50px;
    background: transparent linear-gradient(180deg, #ECECEC 0%, #F9F9F9 35%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #fff;
    background: transparent linear-gradient(180deg, #ECECEC 0%, #F9F9F9 35%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: -3px -4px 8px #FFFFFF9C;
    border: 1px solid #FFFFFF;
    border-radius: 11px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#detail_list_wrap{
    margin-top: 25px;
    min-height:33px;
    .inner{
        display: flex;
        align-items: center;    
    }
    .list_title{
        font-size: 16px;
        padding-right: 15px;
        margin-right: 15px;
        position: relative;
        display: flex;
        align-items: center;
        height: 25px;
        border-right: 1px #a2a2a2 solid;
        font-weight: bold;
    }
    #detail_list{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1;
        a{
            display: flex;
            overflow: hidden;
            width: 86px;
            height: 28px;
            font-size: 12px;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 5px;   
            position: relative;
            margin:2.5px 0 2.5px 5px;
            .member_name{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                color:#fff;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    line-height: normal;
                    position: relative;
                    top: -1px;
                    opacity: .5;
                }
                span{
                    padding:0 2px;
                    font-size: 12px;
                    &:first-child{
                        font-weight: bold;
                    }
                }
            }
            &.support_days{
                border: 1px #000 solid;
                .member_name{
                    color: #000;
                }
            }
            &.move_days{
                .member_name{
                    &:after{
                        content: '(挪)';
                    }
                }
            }
        } 
    }
}
</style>
