<template>
    <ShiftLightbox 
        :isScheduleCanlender="true"
        :eventSelectedMemberID="eventSelectedMemberID"
        :eventSelectedEventDate="eventSelectedEventDate"
    />
    <div class="schedule">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">芳療師假表</div>
            <div class="canlender_search">
                <label 
                    class="cus_checkbox"
                    for="view_all_schedules"
                >
                    <input 
                        type="checkbox" value="" 
                        id="view_all_schedules"
                        v-model="viewAllSchedules"
                        @change="toggleViewAllSchedules"
                    >
                    <span></span>
                    查看全店班表
                </label>  
                <BaseSelect
                    title="芳療師"
                    rootClass="aroma-selector style2"
                    name="selected_aroma"
                    placeholder="請選擇芳療師"
                    :options="aromatherapistList"
                    :view-all-schedules="viewAllSchedules"
                />  
                <BaseClassSelect
                    title="假別"
                    rootClass="aroma-selector"
                    name="selected_class"
                    placeholder="請選擇假別"
                    :options="aromatherapistClassList"
                    :view-all-schedules="viewAllSchedules"
                    @selectChange="handleSelectChange"
                /> 
                <div id="cancel_all" @click="clearAllSelected">清除</div>
            </div>
            <div class="content-panel">
                <StContainer_canlender 
                    :view-all-schedules="viewAllSchedules"
                    :selected-aroma-id="selectedAromaId" 
                    :selected-leave-type="selectedLeaveType" 
                    @memberIdSelected="handleMemberIdSelected"
                    @selectedEventDate="handleSelectedEventDate"
                />
            </div>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import Logo from "@/components/includes/Logo";
import ShiftLightbox from "@/components/lightbox/ShiftLightbox";
import StContainer_canlender from "@/components/schedule/StContainer_canlender";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

import { computed, watch, ref, onMounted} from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";

export default {
    name: "Schedule_canlender",
    components: {
        Logo,
        ShiftLightbox,
        StContainer_canlender,
        FixedOrderInfo
    },
    setup(){
        const { state, commit, dispatch} = useStore();
        const storeId = computed(() => state.auth.store_id);
        const viewAllSchedules = ref(false);
        const selectedAromaId = ref(null);
        
        // 當前選取的芳療師
        const { setFieldValue, values } = useForm();

        watch(() => values.selected_aroma, (id) => {
            if(!id && id !== 0) return;
            selectedAromaId.value = id;
        });

        // 芳療師名單
        const aromatherapistList = computed(() => {
            let list = state.reservation.aromatherapistList.map(item =>{
                return {
                    id: item.id,
                    name: `${item.member_code}${item.nickname}`
                }
            });

            if (list.length) {
                list.unshift({
                    id: 0,
                    name: "全部",
                });

                setFieldValue("selected_aroma", list[0].id);
            }

            return list;
        });

        // 取得芳療師名單
        dispatch("reservation/getAromatherapistList", {
            store_id: storeId.value,
        });

        // 假別
        const aromatherapistClassList = ref([
            // {
            //     'leave_type' : null,
            //     'note' : '全部',
            //     'color' : 'rgba(255,255,255,1)'
            // },
            {
                'leave_type' : 1,
                'note' : '榮譽假',
                'color' : 'rgba(183, 127, 26, 1)'
            },
            {
                'leave_type' : 2,
                'note' : '半天榮譽假',
                'color' : 'rgba(109, 69, 69, 1)'
            },
            {
                'leave_type' : 3,
                'note' : '例假日',
                'color' : 'rgba(0, 121, 242, 1)'
            },
            {
                'leave_type' : 4,
                'note' : '休息日',
                'color' : 'rgba(5, 170, 102, 1)'
            },
            // {
            //     'leave_type' : 5,
            //     'note' : '請假',
            //     'color' : 'rgba(255, 65, 111, 1)'
            // },
            // {
            //     'leave_type' : 8,
            //     'note' : '支援',
            //     'color' : 'rgba(255,255,255,1)'
            // },
            {
                'leave_type' : 9,
                'note' : '曠職',
                'color' : 'rgba(0, 0, 0, 1)'
            },
            {
                'leave_type' : 10,
                'note' : '培訓期',
                'color' : 'rgba(255, 174, 21, 1)'
            } 
        ]);
        
        const toggleViewAllSchedules = (event) => {
            viewAllSchedules.value = event.target.checked;
            // console.log('checked',viewAllSchedules.value)
        };

        const clearAllSelected = ()=>{
            selectedAromaId.value = 0;
            setFieldValue("selected_aroma", 0);
            setFieldValue("selected_class", null);
        }

        const selectedLeaveType = ref(null);
        const handleSelectChange = (value) => {
            selectedLeaveType.value = value;
        };

        const eventSelectedMemberID = ref(null);
        const eventSelectedEventDate = ref(null);

        const handleMemberIdSelected = (memberId) => {
            eventSelectedMemberID.value  = memberId
        };
        const handleSelectedEventDate = (eventDays) => {
            eventSelectedEventDate.value = eventDays;
        };

        return {
            aromatherapistList,
            aromatherapistClassList,
            storeId,
            toggleViewAllSchedules,
            viewAllSchedules,
            selectedAromaId,
            clearAllSelected,
            handleSelectChange,
            selectedLeaveType,
            eventSelectedMemberID,
            eventSelectedEventDate,
            handleMemberIdSelected,
            handleSelectedEventDate
        };
    }
};
</script>

<style lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.schedule {
    .index-container {
        position: relative;
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }

    .canlender_search{
        display: flex;
        align-items: center;
        position: absolute;
        right: 30px;
        top: -15px;
        label.cus_checkbox{
            display: flex;
            align-items: center;
            font-weight: bold;
            min-width: 128px;
            input{
                opacity: 0;
                width: 0;
                height: 0;
            }    
            span{
                width: 23px;
                height: 23px;
                border: 2px #707070 solid;
                border-radius: 4px; 
                margin-right: 9px;  
                position: relative;
                &:before{
                    content: '';
                    width: 7px;
                    height: 13px;
                    border-right: 2px solid #fff; 
                    border-bottom: 2px solid #fff;
                    position: absolute;
                    left: 4px;
                    top: 8px;
                    transform: rotate(45deg) translate(-50%,-50%); 
                    opacity: 0;
                    transition: .4s ease;
                } 
            }
            input:checked ~ span{
                background: #000;   
                border: 2px #000 solid;
                &:before{
                    opacity: 1;
                }
            }
        } 
        .aroma-selector{
            max-width: 295px;
            margin-bottom: 0;
        }   
    }

    .content-panel {
        padding: 28px 22px 46px 33px;
        border: 1px solid #fff;
        border-radius: 15px;
        box-shadow: 0 34px 77px 0 rgba(0, 0, 0, 0.26);
        background: linear-gradient(143deg, #fff, #f1eeee, #fff);
        @media (max-width: 1200px) {
            padding: 20px;
        }
        overflow: overlay;
    }

    .aroma-selector.style2{
        margin: 0 12px;
    }

    .aroma-selector{
        min-width: 295px;
    }

    #cancel_all{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86px;
        height: 52px;
        color: #fff;
        background: transparent linear-gradient(299deg, #EBC496 0%, #A4641A 36%, #A4641A 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF85;
        border-radius: 11px;
        opacity: 1;
        margin-left: 12px;
        cursor: pointer;
        position: relative;
        overflow: auto;
        &:before{
            content: '';
            box-shadow: inset 0 0 0 #00000057, 0 0 0 #00000029;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transition: .2s linear ;
        }
        &:hover{
            border-color: rgb(239, 155, 53);;
            background: transparent linear-gradient(299deg, #EBC496 0%, #A4641A 36%, #A4641A 100%) 0% 0% no-repeat padding-box;
            &:before{
                box-shadow: inset 0px 11px 20px #00000057, 0px 3px 6px #00000029;
            }
        }
    }
}
</style>
